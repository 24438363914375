import React from 'react'
import "./home.css"
import Typewriter from 'typewriter-effect';
import { Button, ButtonGroup, Image, Text } from '@chakra-ui/react'
import { Link } from 'react-scroll'
// import "../Components/nav.css"
import { db } from '../../Database/firebaseConfig'
import { addDoc, collection } from 'firebase/firestore'


import Education from '../Education';
import Projects from '../projects/Projects';
import About from '../about/About';
import Intro from '../intro/Intro';
// import Projects from '../projects/Projects';


export default function Home() {

 



  return (
    <div className='homeContainer'>
      <div className='navItems'>



        <div className='df'>
          df.

        </div>
        {/* <div className='item1'>
          <Link to='test1' spy={true} smooth={true} offset={50} duration={500} >Education</Link>

        </div>


        <div className='item2'>
          <Link to='test2' spy={true} smooth={true} offset={100} duration={1000} >About</Link>

        </div>
        <div className='item3'>
          <Link to='test2' spy={true} smooth={true} offset={100} duration={1000} >About</Link>

        </div> */}



      </div>


    

      <div className='comp1'>
        <Intro></Intro>

      </div>
      <div className='comp2'>
       {/* <Education></Education> */}

      </div>


    
  




    </div>

  )
}
