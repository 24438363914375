import './App.css';
import About from './Components/about/About';
import Education from './Components/intro/Intro';
import Home from './Components/home/Home';
import Nav from './Components/Nav';
import Page from './Components/Page';
import {Routes, Route} from "react-router-dom"


function App() {
  return (
    <div className="">

      <Routes>
        <Route path='/' element={<Home/>}/>
        
   


        
      </Routes>
    
      {/* <Nav></Nav>

      <Home></Home>
      <About></About>
      <Education></Education> */}


    </div>
  );
}

export default App;
