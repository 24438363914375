import React from 'react'
import "./intro.css"
import { Image, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import Typewriter from 'typewriter-effect';
import github from './githubicon.svg'
import linkedIn from './gmailicon.svg'
import gmail from './linkedInicon.svg'
import resume from './resumeicon.svg'



export default function Intro() {
  return (
    <div className='eduContainer'>

      <Text className='introTitle'>


        <Typewriter
          options={{

            // loop: true
          }}
          onInit={(typewriter) => {
            typewriter.changeDelay(75)
              .typeString("Hi, I'm Dennis.")

              .pauseFor(2400)
              .start()

          }}


        />
      </Text>

      <div className='introContainer'>
        {/* <Text className='intro'> I'm a software engineer based in Los Angeles, California. I graduated from UC Berkeley with a bachelors in Data Science. I enjoy building tools for backend web development </Text> */}
        <Text className='intro1'> I'm a Software Engineer based in Los Angeles, California.</Text>
        {/* <Text className='intro2'> I graduated from the University of California, Berkeley with a bachelors in  Data Science. </Text> */}
        <Text className='intro2'> I studied Data Science at the University of California, Berkeley. </Text>

        {/* <Text className='intro2'>I graduated from the University of California, Berkeley with a bachelors in  Data Science.</Text> */}
        <div className='cons'>
          <div className='cc1'>Contact me:</div>
          <Link href='https://github.com/DarrFranco' className='ic1' > <Image src={github}></Image> </Link>
          <Link href='mailto:dennis.d.franco@gmail.com' className='ic2' > <Image src={linkedIn}></Image> </Link>
          <Link href='https://www.linkedin.com/in/ddfranco/' className='ic3' > <Image src={gmail}></Image> </Link>
          {/* <Linåk href='https://drive.google.com/file/d/1Y4-ac8kUaQdhrcCAv4Mf499TD7stOhkw/view?usp=sharing' className='ic4' > <Image src={resume}></Image> </Link> */}

        </div>


      </div>
      {/* <Text className='intro' >I graduated from the University of California, Berkeley with a bachelors in  Data Science.</Text> */}

      {/* <Text className='subIntro'> Some of the tech I've recently worked with: </Text> */}


      {/* <Text className='intro'> I'm a software enginner based in Los Angeles, California. I graduated from UC Berkeley with a bachelors in Data Science </Text> */}
      {/* <Text className='intro'> I'm a software enginner based in Los Angeles, California. I graduated from UC Berkeley with a bachelors in Data Science </Text> */}





      {/* <h3 className='test'> Hi, I'm Dennis Franco</h3> */}

      {/* <Button>CLick heres</Button> */}


      {/* <h2> Hi, I'm Dennis</h2> */}


    </div>

  )
}
